.parent {
	margin-top: 16px;
}
.parent > div:first-of-type {
	margin: 0 var(--spacing-m) var(--spacing-xs);
	display: flex;
	align-items: center;
}
.parent > div:first-of-type > span:first-of-type {
	text-transform: uppercase;
	font-weight: bold;
}
.unselect {
	cursor: pointer;
	margin-left: var(--spacing-xs);
	composes: font-xs-medium from global;
	color: var(--shades-blue-500);
}
.divider {
	margin: var(--spacing-s) var(--spacing-l);
}
.warningDialog {
	z-index: 2000;
}
