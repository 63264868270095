.parent {
	color: var(--color-grey-black);
	flex-grow: 0;
	height: 16px;
	margin-bottom: var(--spacing-s);
	text-align: left;
	composes: font-sm-regular from global;
	text-transform: uppercase;
}

.icon {
	color: var(--color-dark-grey-1);
	margin-right: var(--spacing-xs);
}
