.section {
	margin-bottom: 16px;
}

.section .section__title {
	font-weight: bold;
	font-size: var(--font-size-sm);
	text-transform: uppercase;
}

.section .section__content {
	margin: 0 18px;
}
