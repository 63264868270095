.main {
	flex-grow: 1;
	margin-top: var(--spacing-s);
	padding: 0;
	width: 100%;
	height: 100%;
	min-height: 164px;
	overflow: hidden;
}
.listContainer > div > div {
	height: 100%;
}
.breadcrumb {
	display: flex;
	align-items: center;
	padding: 0 6px;
}
.breadcrumb__content {
	width: calc(100% - 114px);
}
.breadcrumb > span {
	display: flex;
	align-items: center;
}
