.main {
	background-color: var(--color-white);
	display: flex;
	flex-direction: column;
	padding: 10px;
	width: 100%;
	height: 100%;
}
.header {
	display: flex;
	align-items: center;
}
.header > span:nth-child(2) {
	margin-left: auto;
}
.title {
	color: var(--shades-grey-900);
	height: 24px;
	text-align: left;
	composes: font-md-bold from global;
}
.search {
	margin: 16px 0;
}
.toggleGroup {
	width: 100%;
}
.toggleGroup__btn {
	height: 26px;
	text-transform: none;
	composes: font-xs-medium from global;
}
.toggleGroup__btn > span:first-of-type > svg {
	margin-right: var(--spacing-xs);
}
.confirm {
	margin-top: var(--spacing-s);
}
.horizontalDivider {
	stroke-width: 1px;
	stroke: var(--color-light-grey-3, #d8d8dc);
}
