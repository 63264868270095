.pdfViewer {
	width: 100%;
	height: 100%;
	background-color: var(--color-white);
	overflow: auto;
}
.pdfViewer[data-has-right-panel="true"][data-right-panel-on="true"] {
	width: calc(100% - 12px) !important;
}
.pdfViewer[data-right-panel-on="false"][data-has-right-panel="true"] {
	width: calc(100% - 62px) !important;
}
.pdfViewer__hideScroll::-webkit-scrollbar {
	display: none;
}
.row {
	background: var(--color-white);
}
.imageContainer {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: auto;
	overflow-y: hidden;
}
.imageContainer .image {
	position: relative;
}
.imageContainer area {
	position: absolute;
}
.image {
	border-bottom: 1px solid var(--color-light-grey-4);
	box-shadow: var(--shadow-2) 0px 10px 36px 0px, var(--shadow-1) 0px 0px 0px 1px;
	z-index: 1;
	-moz-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}
.image__loader,
.image__error {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	transform: translate(-50%, -50%);
}
.pdfViewerList {
	position: relative;
	display: flex;
	width: 100%;
}
.pdf[data-right-panel-on="false"][data-has-right-panel="true"] {
	width: 100%;
}
.pdf[data-right-panel-on="true"][data-has-right-panel="true"] {
	width: calc(100% - 30vw);
}
.rightPanel {
	width: 30vw;
	transition-delay: 0.3s;
}
