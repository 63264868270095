.req-details {
	width: 60%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	position: relative;
}

.req-details__empty-state {
	position: absolute;
	top: 30%;
	text-align: center;
	width: 100%;
}

.req-details__empty-state .req-details__empty-state-title {
	font-weight: bold;
}

.req-details__empty-state .req-details__empty-state-sub-title {
	font-style: italic;
	font-size: var(--font-size-xs);
}

.req-details__content {
	padding: 16px;
}
