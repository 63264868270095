.item {
	align-items: center;
	align-self: stretch;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	gap: 4px;
	height: 24px;
	justify-content: flex-start;
	min-width: 0;
	max-width: 480px;
	width: 100%;
}
.item__no-content {
	background-color: var(--color-light-grey-4-40);
	cursor: default;
}
.itemIcon--padding {
	padding-left: var(--spacing-m);
}
.itemIcon {
	color: var(--color-light-grey-2);
	height: 12px;
	width: 16px;
}
.itemText {
	color: var(--color-grey-black);
	flex-grow: 1;
	height: 16px;
	overflow: hidden;
	text-align: left;
	composes: font-sm-regular from global;
}
.itemIconNext {
	color: var(--color-light-grey-2);
	height: 16px;
	width: 16px;
}
.label {
	width: 250px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid red;
}
.checkboxRoot {
	padding: 4px;
}
.checkboxRoot:hover {
	background-color: transparent;
}
.checkboxIcon {
	font-size: 16px;
}
.expandButton > button {
	height: 24px !important;
	width: 24px !important;
}
.identifierButton > button {
	height: 24px !important;
	width: 32px !important;
}
