.documentButtons {
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: hidden;
}
.documentBtn {
	background-color: var(--shades-grey-400) !important;
	border-radius: 0;
	justify-content: left;
	width: 100%;
}
.documentBtn:nth-child(n + 2) {
	margin-top: 8px;
}
.documentBlock {
	border-bottom: 1px solid var(--shades-grey-300);
	border-radius: 1px;
	font-size: var(--font-size-xs);
	height: fit-content !important;
	overflow-wrap: break-word;
	padding: 2px !important;
	white-space: pre-wrap;
	word-wrap: break-word;
}
.documentBlock:hover {
	background-color: var(--shades-blue-50);
}
.documentBlock[type="isSelected"] {
	background-color: var(--shades-grey-300);
	color: var(--color-black);
}
